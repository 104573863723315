import React, {useState} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import queryString from 'query-string'
import {
    startOfMonth, endOfMonth, startOfYear, endOfYear, startOfQuarter, endOfQuarter,
    addMonths, addYears, addQuarters, isSameDay
} from 'date-fns';
import {DateRangePicker as DRP} from 'react-date-range'
import utils from "../utils";

const staticRangeHandler = {
    range: {},
    isSelected(range) {
        const definedRange = this.range();
        return (
            isSameDay(range.startDate, definedRange.startDate) &&
            isSameDay(range.endDate, definedRange.endDate)
        );
    },
};

const createStaticRanges = (ranges) => {
    return ranges.map(range => ({...staticRangeHandler, ...range}));
}

const defineds = {
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfQuarter: startOfQuarter(new Date()),
    endOfQuarter: endOfQuarter(new Date()),
    startOfLastQuarter: startOfQuarter(addQuarters(new Date(), -1)),
    endOfLastQuarter: endOfQuarter(addQuarters(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
    startOfLastYear: startOfYear(addYears(new Date(), -1)),
    endOfLastYear: endOfYear(addYears(new Date(), -1)),
};

const DateRangePicker = ({defaultStartDate, defaultEndDate, submitUrl}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [startDate, setStartDate] = useState(moment(defaultStartDate).toDate())
    const [endDate, setEndDate] = useState(moment(defaultEndDate).toDate())

    const handleSelect = (ranges) => {
        setStartDate(new Date(ranges.selection.startDate))
        setEndDate(new Date(ranges.selection.endDate))
    }

    const toggleDateRangePicker = () => {
        setIsOpen(!isOpen);
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const stringified = queryString.stringify({
            ...queryString.parse(location.search), // preserve all current query params (i.e. sorting and filters)
            start: moment(startDate).format('YYYY-MM-DD'),
            end: moment(endDate).format('YYYY-MM-DD')
        });

        window.location.href = `${submitUrl}?${stringified}`
    }

    const formattedDateRange = `${moment(startDate).format('MMM D, YYYY')} - ${moment(endDate).format('MMM D, YYYY')}`;

    const styles = {
        container: {
            position: 'relative'
        },
        dateRangeInput: {
            background: 'white',
            minWidth: utils.isMobileDevice() ? "auto" : 250,
            cursor: 'pointer',
            flex: 1
        },
        dateRangePicker: {
            position: 'absolute',
            zIndex: 100,
            right: 0,
            top: 45
        }
    }

    const staticRanges = createStaticRanges([
        {
            label: 'This Month',
            range: () => ({startDate: defineds.startOfMonth, endDate: defineds.endOfMonth}),
        },
        {
            label: 'Last Month',
            range: () => ({startDate: defineds.startOfLastMonth, endDate: defineds.endOfLastMonth}),
        },
        {
            label: 'This Quarter',
            range: () => ({startDate: defineds.startOfQuarter, endDate: defineds.endOfQuarter}),
        },
        {
            label: 'Last Quarter',
            range: () => ({startDate: defineds.startOfLastQuarter, endDate: defineds.endOfLastQuarter}),
        },
        {
            label: 'This Year',
            range: () => ({startDate: defineds.startOfYear, endDate: defineds.endOfYear}),
        },
        {
            label: 'Last Year',
            range: () => ({startDate: defineds.startOfLastYear, endDate: defineds.endOfLastYear}),
        },
    ])

    return (
        <div style={styles.container} className={"dateRangePicker"}>
            <form className="form-inline" onSubmit={handleSubmit}>
                <div className="input-group flex-none">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <i className="fas fa-calendar fa-sm"/>
                        </span>
                    </div>
                    <input className="form-control"
                           style={styles.dateRangeInput}
                           value={formattedDateRange} readOnly
                           data-testid="dateRangeInput"
                           onClick={toggleDateRangePicker}
                    />
                    <div className="input-group-append">
                        <button className="btn btn-primary" data-testid="submitInput">
                            Filter
                        </button>
                    </div>
                </div>
            </form>
            {isOpen && (
                <div data-testid="dateRangePicker"
                     style={styles.dateRangePicker}
                     className="shadow"
                >
                    <DRP ranges={[{startDate, endDate, key: 'selection'}]}
                         onChange={handleSelect}
                         staticRanges={staticRanges}
                         inputRanges={[]}
                    />
                </div>
            )}
        </div>
    )
}

DateRangePicker.propTypes = {
    defaultStartDate: PropTypes.string.isRequired,
    defaultEndDate: PropTypes.string.isRequired,
    submitUrl: PropTypes.string.isRequired
}

export default DateRangePicker;