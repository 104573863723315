import React from "react"
import PropTypes from "prop-types"
import {Pie} from 'react-chartjs-2'

const ReferralStatusPieChart = ({handedOffCount, contactedCount, proposalCount, successCount, noDiceCount}) => {

    const totalCount = handedOffCount + contactedCount + proposalCount + successCount + noDiceCount;
    const labels = [
        {text: "Handed Off", textWithCount: `Handed Off (${handedOffCount})`},
        {text: "Contacted", textWithCount: `Contacted (${contactedCount})`},
        {text: "Proposal", textWithCount: `Proposal (${proposalCount})`},
        {text: "Success", textWithCount: `Success (${successCount})`},
        {text: "No Dice", textWithCount: `No Dice (${noDiceCount})`}
    ]

    const data = {

        labels: labels.map(l => l.textWithCount),

        datasets: [{
            data: [handedOffCount, contactedCount, proposalCount, successCount, noDiceCount],
            backgroundColor: [
                '#6f42c1',
                '#4e73df',
                '#FFCE56',
                '#1cc88a',
                '#e74a3b'
            ],
            hoverBackgroundColor: [
                '#6f42c1',
                '#4e73df',
                '#FFCE56',
                '#1cc88a',
                '#e74a3b'
            ]
        }]
    }

    return (
        <Pie data={data}
             legend={{
                 position: 'right',
             }}

             options={{
                 tooltips: {
                     callbacks: {
                         label: function (tooltipItem, data) {
                             const dataset = data.datasets[0]
                             const count = dataset.data[tooltipItem.index]
                             const percent = Math.round((count / totalCount) * 100)

                             return `${labels[tooltipItem.index].text} (${percent}%)`
                         },
                     },
                 }
             }}
        />
    )
}

ReferralStatusPieChart.propTypes = {
    handedOffCount: PropTypes.number.isRequired,
    contactedCount: PropTypes.number.isRequired,
    proposalCount: PropTypes.number.isRequired,
    successCount: PropTypes.number.isRequired,
    noDiceCount: PropTypes.number.isRequired
}

export default ReferralStatusPieChart
