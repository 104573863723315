import React, {useState} from 'react';
import Modal from 'react-modal';
import moment from 'moment'
import axios from 'axios'

const customStyles = {
    overlay: {
        zIndex: 9999
    },
    content: {
        inset: '50px auto auto 50%',
        transform: 'translateX(-50%)',
        maxWidth: '95vw',
    }
};

const SubscriptionInvoicesModal = ({apiUrl}) => {
    const [showModal, setShowModal] = useState(false)
    const [invoices, setInvoices] = useState([])

    const handleOpenModal = async () => {
        const invoices = await axios.get(apiUrl).then(r => r.data.data);
        setInvoices(invoices)
        setShowModal(true)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }

    const formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'})

    return (
        <React.Fragment>
            <button className="btn btn-link p-0 text-sm"
                    onClick={handleOpenModal}>
                <i className="fas fa-info-circle"/>
            </button>
            <Modal
                isOpen={showModal}
                style={customStyles}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={true}
            >
                <h3 className="mb-3">Invoices</h3>

                <div className="table-responsive">
                    <table className="table text-sm">
                        <thead className="mb-3">
                        <tr>
                            <th className="text-nowrap">Due Date</th>
                            <th className="text-nowrap">Total</th>
                            <th className="text-nowrap">Status</th>
                            <th className="text-nowrap">Vendor</th>
                            <th className="text-nowrap">PDF</th>
                        </tr>
                        </thead>
                        <tbody>
                        {invoices.map((invoice) => (
                            <tr key={invoice.id}>
                                <td className="text-nowrap">{moment.unix(invoice.due_date === null ? invoice.created : invoice.due_date).format("YYYY-MM-DD")}</td>
                                <td className="text-nowrap">{(formatter.format(invoice.amount_due / 100))}</td>
                                <td className="text-nowrap">{invoice.status}</td>
                                <td className="text-nowrap">{invoice.account_name}</td>
                                <td className="text-nowrap">
                                    <a href={invoice.invoice_pdf}>Download</a>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <button className="btn btn-secondary mt-5" onClick={handleCloseModal}>Close</button>
            </Modal>
        </React.Fragment>
    );
}

export default SubscriptionInvoicesModal;