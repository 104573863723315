import React from 'react';
import PropTypes from 'prop-types';
import {Fab, Action} from 'react-tiny-fab';

import 'react-tiny-fab/dist/styles.css';

const ApplicationFab = ({newReferralUrl, newMessageUrl}) => {
    // The Fab is the main button. Pass any component to the icon prop and choose
    // either click or hover for the event (default is hover)

    const handleReferralClick = () => window.location = newReferralUrl
    const handleMessageClick = () => window.location = newMessageUrl

    return (
        <Fab
            icon={<i className="fas fa-plus fa-sm text-white-50"/>}
            mainButtonStyles={{backgroundColor: '#275c7b'}}
            alwaysShowTitle={true}
        >
            <Action
                text="Referral"
                style={{ backgroundColor: '#3a3b45' }}
                onClick={handleReferralClick}
            >
                <i className="fas fa-fw fa-comments-dollar"/>
            </Action>
            <Action
                text="Message"
                style={{ backgroundColor: '#3a3b45' }}
                onClick={handleMessageClick}
            >
                <i className="fas fa-fw fa-envelope"/>
            </Action>
        </Fab>
    )
};

ApplicationFab.propTypes = {
    newReferralUrl: PropTypes.string.isRequired
}

export default ApplicationFab;