// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require('popper.js')
require('bootstrap')


import jQuery from 'jquery';
window.jQuery = jQuery;
window.$ = jQuery;

import 'jquery-match-height'


import { selectize } from '../components/selectize'
import 'select2';

// made globally to help with debugging in dev
window.$ = $

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

const ZERO_KEY = 48
const NINE_KEY = 57
const DELETE_KEY = 8

$(document).ready(() => {
    // TODO: move this somewhere else
    $(document).on('keypress', 'input[type="tel"]', function(event) {
        const {keyCode} = event
        const $el = $(event.target)
        const acceptedKey = (keyCode >= ZERO_KEY && keyCode <= NINE_KEY) || keyCode === DELETE_KEY
        const digits = ($el.val().match(/\d+/g) || []).join("")

        // We need to be careful with phone numbers and only
        // allow numbers because we'll format it exactly
        if (acceptedKey && digits.length < 10) {
            const areaCode = digits.substring(0, 3)
            const prefix = digits.substring(3, 6)
            const lineNumber = digits.substring(6, 10)

            if (digits.length > 5) {
                $el.val(`(${areaCode}) ${prefix}-${lineNumber}`)
            } else if (digits.length > 2) {
                $el.val(`(${areaCode}) ${prefix}`)
            } else {
                $el.val(areaCode)
            }
        } else {
            event.preventDefault()
            event.stopPropagation()
        }
    })

    // init tooltips
    // TODO: clear "click" tooltips on blur / mouse leave
    $('[data-toggle="tooltip"]').tooltip()

    // init clickable elements that navigate a user to another page (i.e. message thread table rows)
    $("[data-href]").click((e) => window.location = $(e.currentTarget).data("href"));

    $('.js-pricing-block').matchHeight();

    selectize();
});

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
