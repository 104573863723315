import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"

const TemperatureButton = ({value, className, onClick}) => {
    return (
        <button type="button"
                className={className}
                style={{maxWidth: 80, textAlign: 'left'}}
                data-testid={`temperature_${value}`}
                onClick={() => onClick(value)}
        >{value}</button>
    );
}

const TemperatureGaugeInput = ({defaultValue, inputFieldId}) => {
    const [temperature, setTemperature] = useState(defaultValue || 1)

    // ensure we default the external input field correctly on initial load
    // without this we run this risk of not persisting the correct data between sessions
    useEffect(() => {
        document.getElementById(inputFieldId).value = temperature
    }, [])

    const handleButtonClick = (newTemperature) => {
        setTemperature(newTemperature)
        document.getElementById(inputFieldId).value = newTemperature
    }

    const getButtonClasses = (btnTemperatureValue) => {
        if (btnTemperatureValue <= temperature) {
            return "btn btn-lg btn-danger border border-danger flex-1 active"
        } else {
            return "btn btn-lg btn-light border flex-1"
        }
    }

    return (
        <div className="btn-group d-flex" role="group">
            <TemperatureButton value={1} className={getButtonClasses(1)} onClick={handleButtonClick}/>
            <TemperatureButton value={2} className={getButtonClasses(2)} onClick={handleButtonClick}/>
            <TemperatureButton value={3} className={getButtonClasses(3)} onClick={handleButtonClick}/>
            <TemperatureButton value={4} className={getButtonClasses(4)} onClick={handleButtonClick}/>
            <TemperatureButton value={5} className={getButtonClasses(5)} onClick={handleButtonClick}/>
        </div>
    );
};

TemperatureGaugeInput.propTypes = {
    defaultValue: PropTypes.number,
    inputFieldId: PropTypes.string.isRequired
};

export default TemperatureGaugeInput;
