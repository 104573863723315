import 'selectize/dist/js/selectize.min.js';
import 'selectize/dist/css/selectize.bootstrap3.css';

const selectize = () => {
    $("#member_widget_allowed_domains").selectize({
        delimiter: ",",
        persist: false,
        create: function (input) {
            return {
                value: input,
                text: input,
            };
        },
    });
};

export { selectize };