import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

const FilterGroupCheckboxToggle = ({filterGroupId}) => {
    const [checkboxes, setCheckboxes] = useState([])
    const [allChecked, setAllChecked] = useState(false)

    useEffect(() => {
        const filterGroup = document.getElementById(filterGroupId)
        const filterGroupCheckboxes = filterGroup.querySelectorAll('input[type="checkbox"]')

        setCheckboxes(Array.from(filterGroupCheckboxes))
    }, [])

    useEffect(() => {
        if (checkboxes) {
            updateAllChecked()

            // Listen for dom changes to keep React component in sync
            checkboxes.forEach(checkbox => checkbox.addEventListener('click', updateAllChecked))
        }
    }, [checkboxes])

    const updateAllChecked = () => setAllChecked(checkboxes.every(checkbox => checkbox.checked))

    const handleClick = () => {
        checkboxes.forEach(checkbox => checkbox.checked = !allChecked)
        updateAllChecked()
    }

    const text = allChecked ? "Reset All" : "Select All"

    return (
        <button className="btn btn-link p-0" type="button" onClick={handleClick}>
            <small>{text}</small>
        </button>
    )
}

FilterGroupCheckboxToggle.propTypes = {
    filterGroupId: PropTypes.string.isRequired,
}

export default FilterGroupCheckboxToggle