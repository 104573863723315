import React, {useEffect} from 'react';
import {useWindowSize} from "react-use";
import $ from 'jquery';
import utils from "../utils";

const collapseSubMenus = () => {
    $('.sidebar .collapse').collapse('hide')
}

const toggleSidebar = () => {
    const $sidebar = $(".sidebar");
    $("body").toggleClass("sidebar-toggled");
    $sidebar.toggleClass("toggled");
    if ($sidebar.hasClass("toggled")) {
        collapseSubMenus()
    }
}

const SidebarToggleButton = () => {
    const {width} = useWindowSize();

    useEffect(() => {
        if (utils.isMobileDevice()) {
            // Hide sidebar by default on mobile
            toggleSidebar()
        }
    }, [])

    useEffect(() => {
        if (utils.isMobileDevice()) {
            collapseSubMenus()
        } else {
            const $sidebar = $(".sidebar");
            $("body").removeClass("sidebar-toggled")
            $sidebar.removeClass("toggled")
        }
    }, [width])

    const handleToggleClick = () => {
        toggleSidebar()
    };

    return (
        <button id="sidebarToggleTop" className="btn btn-link rounded-circle mr-3"
                onClick={handleToggleClick}>
            <i className="fa fa-bars"/>
        </button>
    )
}

export default SidebarToggleButton;