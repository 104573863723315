import React from "react"
import PropTypes from "prop-types"

const TemperatureGauge = ({temperature}) => {
    const getStyles = () => {
        return {
            width: Math.round(temperature / 5 * 100) + "%",
            height: 15
        };
    }

    return (
        <div className="progress">
            <div className="progress-bar bg-danger"
                 style={getStyles()}
                 role="progressbar"
                 aria-valuenow="0"
                 aria-valuemin="0"
                 aria-valuemax="100"
            />
        </div>
    );
};

TemperatureGauge.propTypes = {
    temperature: PropTypes.number
};

export default TemperatureGauge;
