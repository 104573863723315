import React from "react"
import PropTypes from "prop-types"
import {parsePhoneNumberFromString} from 'libphonenumber-js'

/*
 * TODO: tests
 *
 * 1. No string value
 * 2. International value
 * 3. national value
 * 4. value with dashes
 * 5. value with no dashes
 */
const PhoneNumberLink = ({value}) => {
    if (!value) return ""

    const phoneNumber = parsePhoneNumberFromString(value, 'US')

    if (phoneNumber && phoneNumber.isValid()) {
        const uri = phoneNumber.getURI()
        const national = phoneNumber.formatNational()
        return <a href={uri} target="_blank" data-testid="link">{national}</a>
    } else {
        return value
    }
}

PhoneNumberLink.propTypes = {
    value: PropTypes.string
}

export default PhoneNumberLink