import React, {useState} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

const LoadCrossChapterMembersButton = ({networkChaptersWithMembers, selectFieldId}) => {
    const [disabled, setDisabled] = useState(false)
    const [text, setText] = useState("Load Cross Chapter Members")

    const createMemberOption = (member) => {
        return `<option value="${member.id}">${member.first_name} ${member.last_name} (${member.email})</option>`
    }

    const loadCrossChapterMembers = () => {
        const selectField = $(`#${selectFieldId}`)
        selectField.empty()

        networkChaptersWithMembers.forEach(chapter => {
            const memberOptions = chapter.users.map(createMemberOption);
            selectField.append(`<optgroup label="${chapter.name}">${memberOptions}</optgroup>`)
        })

        setDisabled(true)
        setText("Cross Chapter Members Loaded!")
    }

    return (
        <button className="btn btn-link btn-sm" type="button" disabled={disabled}
                onClick={loadCrossChapterMembers} data-testid="loadCrossChapterMembersBtn"
        >{text}</button>
    )
};

LoadCrossChapterMembersButton.propTypes = {
    networkChaptersWithMembers: PropTypes.array.isRequired,
    selectFieldId: PropTypes.string.isRequired
}

export default LoadCrossChapterMembersButton;