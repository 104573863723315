import React, {useEffect, useState} from "react"
import {Calendar} from 'react-date-range'

const DatePicker = ({initialDate, inputFieldId}) => {
    const [date, setDate] = useState(new Date(initialDate))
    const [showCalendar, setShowCalendar] = useState(false)
    const inputField = document.getElementById(inputFieldId)

    useEffect(() => {
        inputField.addEventListener('focus', () => setShowCalendar(true))
        setInputFieldValue(date)
    }, []);

    const setInputFieldValue = (newDate) => {
        // convert date into UTC
        const month = String(newDate.getUTCMonth() + 1).padStart(2, "0")
        const day = String(newDate.getUTCDate()).padStart(2, "0")
        const year = newDate.getUTCFullYear()
        document.getElementById(inputFieldId).value = `${year}-${month}-${day}`
    }

    const handleSelect = (newDate) => {
        setInputFieldValue(newDate)
        setDate(newDate)
        setShowCalendar(false)
    }

    const containerStyles = {
        position: 'absolute',
        border: 'solid 1px #e2e2e2',
        visibility: showCalendar ? "visible" : "hidden"
    }

    return (
        <div style={containerStyles}>
            <Calendar date={date}
                      onChange={handleSelect}
            />
        </div>
    )
}

export default DatePicker;