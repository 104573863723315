import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'

const SidebarBrand = ({networks, networkLogoImageUrl}) => {
    useEffect(() => {
        $('.sidebar-brand').popover({
            html: true,
            content: networks.map((n) => `<a href="/networks/${n.id}/set_session">${n.name}</a>`).join("</br>"),
            placement: "bottom",
        })
    }, [networks])

    const togglePopover = (e) => {
        e.preventDefault()
        $('.sidebar-brand').popover('toggle')
    }

    return (
        <a href="/" className="sidebar-brand d-flex align-items-center justify-content-center"
            onClick={togglePopover}>
            <div className="sidebar-brand-logo">
                <img src={networkLogoImageUrl}  alt=""/>
            </div>
        </a>
    )
};

SidebarBrand.propTypes = {
    networks: PropTypes.array.isRequired,
    networkLogoImageUrl: PropTypes.string.isRequired
}

export default SidebarBrand;