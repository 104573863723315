import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ClipboardJS from "clipboard";

const ALL_CHAPTERS = "all"

const MemberWidgetSnippet = ({network, chapters, memberWidget}) => {
    const [chapterId, setChapterId] = useState(ALL_CHAPTERS)

    useEffect(() => {
        new ClipboardJS('.btn')
    }, [])

    const getSnippetChapterFilterText = () => {
        if (chapterId === ALL_CHAPTERS) {
            return `networkId: ${network.id}`
        }

        return `chapterId: ${chapterId}`
    }

    const getSnippet = () => {
        const snippet = `
<div id="MembersWidget"></div>
<script src="https://app.referralrunway.com/members_widget.js?version=1.0.0"></script>
<script>
    window.ReferralRunway.initMembersWidget("MembersWidget", {
        ${getSnippetChapterFilterText()},
        apiKey: "${memberWidget.api_key}"
    })
</script>`

        return snippet.trim()
    }

    return (
        <div className="form-row">
            <div className="col-xl-6 form-group">
                <label className="col-form-label">Chapter</label>
                <select
                    className="form-control"
                    value={chapterId}
                    onChange={(e) => setChapterId(e.currentTarget.value)}
                >
                    <option value={ALL_CHAPTERS}>All Chapters</option>
                    {chapters.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                </select>
            </div>
            <div className="col-sm-12">
                <pre className="p-2 mb-2 border"><code>{getSnippet()}</code></pre>
            </div>
            <div className="col-sm-12">
                <button
                    type="button"
                    className="btn btn-primary"
                    data-clipboard-text={getSnippet()}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    data-trigger="click"
                    title="Copied!"
                >Copy to clipboard
                </button>
            </div>
        </div>

    )
};

MemberWidgetSnippet.propTypes = {
    network: PropTypes.object.isRequired,
    memberWidget: PropTypes.object.isRequired,
}

export default MemberWidgetSnippet;