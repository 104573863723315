import React from 'react'
import {Line} from 'react-chartjs-2'
import PropTypes from 'prop-types'
import moment from 'moment'
import Color from 'color'

const ReferralQuantityChart = ({referralsReceived, referralsSent}) => {

    const DEFAULT_MONTHLY_TOTALS = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    const DEFAULT_MONTHLY_LABELS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    const getReferralMonthlyTotals = (referrals) => {
        const monthlyTotals = [...DEFAULT_MONTHLY_TOTALS]

        referrals.forEach(referral => {
            const monthIndex = moment(referral.created_at).month()
            monthlyTotals[monthIndex] += 1
        })

        return monthlyTotals
    }

    const data = {
        labels: DEFAULT_MONTHLY_LABELS,
        datasets: [
            {
                label: 'Received',
                fill: false,
                lineTension: 0,
                backgroundColor: '#275c7b',
                borderColor: Color('#275c7b').lighten(0.2).string(),
                data: getReferralMonthlyTotals(referralsReceived)
            },
            {
                label: 'Sent',
                fill: false,
                lineTension: 0,
                backgroundColor: '#999999',
                borderColor: Color('#999999').lighten(0.5).string(),
                data: getReferralMonthlyTotals(referralsSent)
            }
        ]
    }

    return (
        <Line data={data}
              options={{
                  legend: {
                      align: 'end'
                  },
              }}
        />
    )
}

ReferralQuantityChart.propTypes = {
    referralsReceived: PropTypes.array.isRequired,
    referralsSent: PropTypes.array.isRequired
}

export default ReferralQuantityChart